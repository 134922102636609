.flex-column-container{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%; 
    height: 100%;
}

.flex-row-container{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%; 
    height: 100%;
}

.flex-column-container > div{
    width: 100%; 
    height: 100%;
    padding: 10px;
}

.flex-row-container > div{
    width: 100%; 
    height: 100%;
    padding: 10px;
}

.flex-column-container .flex-column-container-row-separator{
    background-color: gray;
    height: 4px;
    width: 80%;
    align-self:center;
    padding: 0px;
}

.flex-row-container .flex-row-container-column-separator{
    background-color: gray;
    width: 4px;
    height: 80%;
    align-self:center;
    padding: 0px;
}

.link-button{
    cursor: pointer;
    text-decoration: underline;
}

div.no-padding-container-row{
    padding: 0px;
}

div.no-right-padding-container-column{
    padding-right: 0px;
}

div.row-container-column-percent-40{
    width:40%;
}

div.row-container-column-percent-50{
    width:50%;
}

div.row-container-middle-column-percent-20{
    width:20%;
    margin-left: 10px
}

div.right-aligned-content{
    display: flex;
    justify-content: flex-end;
}

div.ui.input.full-width{
    width: 100%;
}

button.ui.button.full-width{
    width: 100%;
}

div.container-box-row{
    border: gray solid 2px;
}

div.container-row-40-percent{
    height: 40%;
}

div.row-container-even-space{
    justify-content: space-evenly;
}

div.home-login-size{
    width: 35rem;
}

.action-cursor{
    cursor: pointer;
}

div.home-container{
    width: 100%;
    height: 100%;
}

div.home-container > div.home-container-lastrow{
    height: 85%;
}

div.home-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

div.home-card > div.home-card-body{
    height: 100%;
}

div.home-card-body > .ui.segment{
    height: 100%;
}

div.page-container{
    min-height: 100%;
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.main-footer-caption{
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
}

.ant-col.footer-center-align-items{
    display: flex;
    align-items: center;
}

.full-height-container{
    height: 100%;
}

.full-height-container.center-align-items{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.data-audit-tabs {
    height: 100%;
}

.full-height-flex-container > .data-audit-tabs{
    height: 100%;
}

.main-login-image-carousel-container, .main-login-image-carousel-container div{
    height: 100%;
}

.main-login-image-carousel-container .ant-image{
    display: flex;
    align-items: center;
}

.checkbox-item-required::before{
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    content: '*';
}

.no-verticle-scroll{
    overflow-y: hidden;
}

.verticle-scroll{
    overflow-y: auto;
    padding-right: 1rem;
}

.error-row td{
    border-top: 1px solid red !important;
    border-bottom: 1px solid red !important;
    cursor: pointer;
}

.error-row td:first-child {
    border-left: 1px solid red !important;
    cursor: pointer;
}

.error-row td:last-child {
    border-right: 1px solid red !important;
    cursor: pointer;
}

.default-row td{
    cursor: pointer;
}

.hide-table-row {
    display: none;
}

.verticle-spaced-grid-rows > .ant-row:not(:first-child){
    margin-top: 1.5rem;
}
.ant-table-cell>.ant-form-item {
    margin-bottom: unset;
}

.ant-table-cell>.ant-form-item {
    margin-bottom: unset;
}

.ant-table-footer>.list-footer {
    text-align: right;
    margin-bottom: unset;
}

.list-footer>.ant-form-item {
    margin-bottom: unset;
}

.layout-container {
    min-height: 90%;
    margin-left: 2rem;
    margin-top: 4rem;
}

.layout-row {
    min-height: 100%;
    overflow-x: auto;
    flex: 1;
}

.layout-row-stretched {
    overflow-x: auto;
    flex: 1;
}

.layout-row .layout-row,
.layout-row .layout-row-stretched {
    overflow-x: unset;
}

.layout-content {
    min-height: 100%;
    background: #fff;
}

.ant-layout.page-layout {
    background: #fff;
    height: 100%;
}

.page-layout>.layout-header {
    height: 5vh;
    padding: unset;
}

.page-layout>.layout-footer {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    background: #E8E8E8;
}

.ant-col.footer-right-column {
    text-align: right;
    align-self: center;
}

.ant-col.footer-right-column>.ant-btn-link {
    padding: unset;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #8C8C8C;
}

.table-footer-row {
    height: 10%;
}

.schema-fields-list {
    height: 66vh;
}

.schema-fields-list .ant-table-content {
    height: 60vh;
}

.schema-fields-list .ant-table-placeholder {
    height: 60vh;
}

.form-table .ant-table-cell {
    vertical-align: top;
}

.form-table .ant-table-selection-column .ant-radio-wrapper {
    display: none;
}

.page-container>.ant-form {
    display: flex;
    flex: 1 1 auto;
}

.ui.segments .ant-form-item,
.segment-group-header-item.ant-form-item {
    margin-bottom: unset;
}

.ui.segments .ant-input-number {
    width: 100%;
}

.ant-row.bottom-gap-15 {
    margin-bottom: 15px;
}

.ant-form-item.no-bottom-margin {
    margin-bottom: unset;
}

.ant-table-wrapper.margin-top-small {
    margin-top: 1em;
}

.wrap-label>.ant-form-item-label {
    white-space: normal;
}

.ant-layout-content.full-height-flex-container {
    height: 100%;
}

.ant-row.bread-crumbs-container {
    height: 4%;
}

.ant-row.page-body-container {
    height: 96%;
    flex: 1;
}

.ant-row.middle-row {
    margin-top: 1rem;
}

.full-height-flex-container .ant-tabs-content-holder,
.full-height-flex-container .ant-tabs-content,
.full-height-flex-container .ant-tabs-tabpane,
.full-height-flex-container .ant-table-wrapper,
.full-height-flex-container .ant-spin-nested-loading,
.full-height-flex-container .ant-spin-container,
.full-height-flex-container .ant-table,
.full-height-flex-container .ant-table-container {
    height: 100%;
}

.full-height-flex-container .ant-table-body {
    height: calc(100% - 39px);
    /* 39px is height of table header */
}

.table-body-size-70 .ant-table-body {
    height: 70%;
}

.paginated-table.ant-table-wrapper,
.paginated-table .ant-spin-nested-loading,
.paginated-table .ant-spin-container {
    height: 100%;
}

.paginated-table .ant-table {
    height: unset;
}

.paginated-table .ant-table-container {
    height: auto
}

.container-height-100,
.container-height-100.ant-table-wrapper,
.container-height-100 .ant-spin-nested-loading,
.container-height-100 .ant-spin-container {
    height: 100%;
}

.container-height-95,
.container-height-95.ant-table-wrapper {
    height: 90%;
}

.container-height-95 .ant-spin-nested-loading,
.container-height-95 .ant-spin-container {
    height: 100%;
}

.container-height-90,
.container-height-90.ant-table-wrapper {
    height: 90%;
}

.container-height-90 .ant-spin-nested-loading,
.container-height-90 .ant-spin-container {
    height: 100%;
}

.container-height-85,
.container-height-85.ant-table-wrapper {
    height: 85%;
}

.container-height-85 .ant-spin-nested-loading,
.container-height-85 .ant-spin-container {
    height: 100%;
}

.container-height-80,
.container-height-80.ant-table-wrapper {
    height: 80%;
}

.container-height-80 .ant-spin-nested-loading,
.container-height-80 .ant-spin-container {
    height: 100%;
}

.container-height-75,
.container-height-75.ant-table-wrapper {
    height: 75%;
}

.container-height-75 .ant-spin-nested-loading,
.container-height-75 .ant-spin-container {
    height: 100%;
}

.container-height-70,
.container-height-70.ant-table-wrapper {
    height: 70%;
}

.container-height-70 .ant-spin-nested-loading,
.container-height-70 .ant-spin-container {
    height: 100%;
}

.container-height-60,
.container-height-60.ant-table-wrapper {
    height: 60%;
}

.container-height-60 .ant-spin-nested-loading,
.container-height-60 .ant-spin-container {
    height: 100%;
}

.container-height-50,
.container-height-50.ant-table-wrapper {
    height: 50%;
}

.container-height-50 .ant-spin-nested-loading,
.container-height-50 .ant-spin-container {
    height: 100%;
}

.container-height-45,
.container-height-45.ant-table-wrapper {
    height: 45%;
}

.container-height-45 .ant-spin-nested-loading,
.container-height-45 .ant-spin-container {
    height: 100%;
}

.container-height-40,
.container-height-40.ant-table-wrapper {
    height: 40%;
}

.container-height-40 .ant-spin-nested-loading,
.container-height-40 .ant-spin-container {
    height: 100%;
}

.container-height-25,
.container-height-25.ant-table-wrapper {
    height: 25%;
}

.container-height-25 .ant-spin-nested-loading,
.container-height-25 .ant-spin-container {
    height: 100%;
}

.container-height-10 {
    height: 10%;
}

.login-signup-options-container {
    display: flex;
    flex-direction: row;
}

.login-signup-options-container>.ant-radio-group {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-around;
    background-color: #F0F2F5;
    padding: 0.5rem;
}

.login-signup-options-container>.ant-radio-group>.ant-radio-button-wrapper {
    flex-grow: 1;
    text-align: center;
}

.login-signup-options-container>.ant-radio-group>.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    color: #1890ff;
    border-color: #F0F2F5;
    background-color: #F0F2F5;
}

.ant-col.content-align-right {
    text-align: right;
}

.ant-col.full-height-container {
    min-height: 100%;
    height: 100%;
}

.full-height-flex-container .ant-table-pagination {
    margin: 5px 0;
}

.schema-list-table .ant-table-selection-column .ant-radio-wrapper {
    display: none;
}

.schema-data-columns {
    overflow-x: auto;
}

.schema-data-columns .ant-list-item {
    overflow-wrap: break-word;
}

.schema-data-columns .ant-row:nth-of-type(1) {
    border: 1px solid #d9d9d9 !important;
}

.schema-data-columns .data-column-mapper-row.ant-row {
    border: unset !important;
}

.schema-data-columns .ant-row:nth-of-type(1)>div {
    border-right: 1px solid #d9d9d9 !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
}

.schema-data-columns .ant-row:nth-of-type(1)>.data-column-mapper-column.ant-col {
    border: unset !important;
    padding: unset !important;
}

.schema-data-columns .ant-row:nth-of-type(1)>div:last-child {
    border-right: unset !important;
}

.closable-container.ant-alert {
    background: white;
    border: unset;
    padding: unset;
}

.full-height-container-layout.ant-layout {
    height: 100%;
    background: unset;
    overflow-y: auto;
}

.full-height-container-layout>.ant-layout-content {
    height: 100%;
    background: unset;
    overflow-y: auto;
}

.full-height-container-layout>.ant-layout-footer {
    padding: unset;
    background: unset;
}

.full-height-container-layout>.ant-layout-footer>*:last-child {
    margin-bottom: 0px;
}

.breadcrumb-item a {
    color: #1890ff;
}

.basic-form-builder {
    margin-right: 1rem;
}

.basic-form-builder .ant-input-number,
.basic-form-builder .ant-picker {
    width: 100%;
}

.tags-summary .ant-space-item {
    margin-right: unset !important;
}

.model-alert-title .ant-modal-header {
    padding: unset !important;
}

.closable-container-stretch,
.closable-container-stretch .ant-alert-content,
.closable-container-stretch .ant-alert-description {
    height: 100%;
}

.closable-container-stretch .ant-alert-message {
    display: none;
}

.text-file-preview,
.text-file-preview .ant-card-body,
.text-file-preview .ant-input {
    height: 100%;
}

.column-spec-card {
    cursor: pointer;
}

.selected-column-spec-card {
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    cursor: pointer;
}

.layout-user-profile-main {
    background: #edf1f2;
    padding: 2rem;
    width: 100%;
    height: 80%;
    box-shadow: 5px 5px 10px 2px rgba(177, 177, 177, .8);
}

.sider-user-profile-menu {
    background: #ffffff;
    height: 100%;
    padding: 2rem;
}

.layout-user-profile-details {
    background: #ffffff;
    height: 80%;
}

.sider-user-profile-details {
    background: #ffffff;
    height: 100%;
}

.user-profile-details-avatar-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.duplicate-dataobject-charts-carousel-dots>li>button {
    background-color: #b0b0b0 !important;
}

.duplicate-dataobject-charts-carousel-dots>li.slick-active>button {
    background-color: #757575 !important;
}

.domain-service-console-table th {
    background: #4472C4 !important;
    color: white !important;
    border: 1px solid white;
}

.colum-center-aligned-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.domain-service-console-statistics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.domain-service-console-statistics>.ant-statistic-title,
.domain-service-console-statistics>.ant-statistic-content {
    color: white;
}

.domain-service-console-statistics>.ant-statistic-title {
    font-size: clamp(.5rem, 1vw, 1.2rem);
}

.domain-service-console-statistics>.ant-statistic-content span {
    font-size: clamp(1rem, 4vw, 4rem);
}

.domain-service-console-statistics-title {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 0.2em;
}

.inline-spin-text.ant-spin-show-text {
    display: flex;
    column-gap: 1rem;
}

.domain-service-console-spinner.ant-spin>.ant-spin-dot>.ant-spin-dot-item {
    background-color: white;
}

.duplicate-search-config-tabs>.ant-tabs-nav {
    margin-bottom: unset;
}

.deduplication-action-menu {
    height: 36px;
}

.deduplication-action-menu .ant-col,
.deduplication-action-menu .ant-space,
.deduplication-action-menu .ant-space-item {
    height: 100% !important;
    display: flex;
    align-items: center;
}

.space-stretched-first-item>.ant-space-item:first-child {
    width: 100%;
}

.share-datarecord-sharelink .ant-space-item:first-child {
    width: 100%
}

.table-empty-body .ant-table-body>table:first-child {
    height: 100%;
}

.tenant-provisioning-progress .ant-progress-outer{
    height: 100%;
}
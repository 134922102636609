.ui.inverted.menu.header-menu{
    margin: 0 0;
    height: 100%;
    min-height: unset;
}

.ui.inverted.menu .item > .item-label {
    margin-left: 1rem;
}

.ui.inverted.menu .item > .header-icon-label {
    margin-left: 1rem;
    margin-right: 2rem;
}


.ui.inverted.menu .active.item {
    background-color: #1890FF;
}

.ui.fluid.container.footer-container{
    margin-top: 1em;
}

.ui.grid.vertical-stretch{
    height: 100%;
}

.ui.segment.full-height-flex-container, .full-height-flex-container{
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: column;
}

.ui.secondary.menu > .active.item.verticle-menu-item{
    background: #E6F7FF;
    color: #1890FF;
}

.ui.segment.full-height-flex-container .page-container{
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
}

.ui.segment.full-height-flex-container .page-container.half-container{
    flex: 0 0 100%;
    max-height: 50%;
    min-height: 50%;
    max-width: 100%;
}

.ui.segment.full-height-flex-container .page-container .layout-row{
    flex: 1 1 auto;
}

.field-properties-container{
    height: 100%;
}

.field-properties-container .ui.segments{
    max-height: 62vh;
    overflow-y: auto;
}

.i.icon.active-icon{
    opacity: 1;
}

i.icon.inactive-icon{
    opacity: 0;
}

.ui.vertical.menu.header-floating-menu > .item{
    text-align: left;
}
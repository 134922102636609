/* For browsers that support `::-webkit-scrollbar-*` pseudo-elements */
@supports selector(::-webkit-scrollbar) {
    /* width */
    .scroll-webkit::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    .scroll-webkit::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    /* Handle */
    .scroll-webkit::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 6px;
    }

    /* Handle on hover */
    .scroll-webkit::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
}

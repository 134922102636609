.svg-font-color svg>path {
    fill: var(--ifm-font-color-base);
}

.queryBuilder {
    min-width: 420px;
}

.queryBuilder .ruleGroup {
    background-color: #e6f4ff;
    border-color: #d9d9d9;
    border-width: 0;
    padding: 1rem;
}

.queryBuilder .ruleGroup .ruleGroup {
    background-color: rgba(146, 206, 252, 0.2);
    border-style: dashed;
    border-width: 1px;
}

.validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid {
    background-color: rgba(102, 51, 153, 0.4);
}

.validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid .ruleGroup-addRule {
    font-weight: bold !important;
}

.validateQuery .queryBuilder .ruleGroup.queryBuilder-invalid>.ruleGroup-header::after {
    content: "Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.";
    color: white;
}

.validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value {
    background-color: rgba(102, 51, 153, 0.4);
}

.validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value::placeholder {
    color: #47246b;
}

html[data-theme="dark"] .validateQuery .queryBuilder .rule.queryBuilder-invalid .rule-value::placeholder {
    color: #945ec9;
}

.queryBuilder .ruleGroup-addGroup+button.ruleGroup-cloneGroup,
.queryBuilder .ruleGroup-addGroup+button.ruleGroup-lock,
/* .queryBuilder .ruleGroup-addGroup+button.ruleGroup-remove, */
.queryBuilder .rule-operators+button.rule-cloneRule,
.queryBuilder .rule-operators+button.rule-lock,
.queryBuilder .rule-operators+button.rule-remove,
.queryBuilder .rule-value+button.rule-cloneRule,
.queryBuilder .rule-value+button.rule-lock,
.queryBuilder .rule-value+button.rule-remove,
.queryBuilder .control+button.rule-cloneRule,
.queryBuilder .control+button.rule-lock,
.queryBuilder .control+button.rule-remove,
.queryBuilder .chakra-select__wrapper+button.rule-cloneRule,
.queryBuilder .chakra-select__wrapper+button.rule-lock,
.queryBuilder .chakra-select__wrapper+button.rule-remove {
    margin-left: auto !important;
}

.queryBuilder .rule-value {
    width: 100%;
}
